.ScreenHome {
  /* border: 1px solid  red; */
  width: 100%;
  height: 100%;

  background: url("../../images/Bg_home2.jpg");
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}



.ScreenHome .play-button {
  font-size: 35pt;
  padding: 30px 45px;
  margin-bottom: 60px;
}
.ScreenHome .logo {
  margin: 0 auto;
  text-align: center;
  width: 500px;
}
.ScreenHome .logo img {
  max-width: 100%;
}
.ScreenHome h1 {
  text-align: center;
  color: #FFF;
  font-size: 42pt;
  font-family: "Geo";
  font-weight: 500;

white-space: pre-wrap;
}
.ScreenHome .spacer {
  flex-grow: 1;
}
.ScreenHome .bottom {
  width: 100%;
  padding: 30px 0;
  background: rgba(115, 108, 90, 0.45);
  background: rgba(176, 131, 187, 0.25) ;
  background: rgba(0, 0, 0, 0.65);
  color: var(--text-color);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScreenHome .bottom > *{
  max-width: 1400px;
}

.ScreenHome .bottom h2 {
  margin: 0;
  font-size: 25pt;
  font-size: 35pt;

  /* margin-bottom: 20px; */
  font-family: "Geo";
  color: var(--main-color);
}
.ScreenHome .bottom p {
  white-space: pre-wrap;
  margin-bottom: 30px;
  font-size: 20pt;
  color: #FFF;

}


.ScreenHome .bottom a{
  color: var(--main-color);
}