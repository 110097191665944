.ScreenGame  {
  width: 0;
  height: 0;
  opacity: 0;
}
.ScreenGame iframe {
  width: 100%;
  height: 100%;

  border: none;
  outline: none;


}
.ScreenGame.visible {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  opacity: 1;
}