.ScreenLogin-content {

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ScreenLogin .LoginForm{
  border: 1px solid  #CCC;

  padding: 30px;

}
.ScreenLogin .LoginForm .LoginForm-columns{
  display: flex;
  gap: 10px;

}
.ScreenLogin .LoginForm .LoginForm-column:first-child{
  padding: 0 10px;
  border-right: 1px solid  #CCC;
}