.Footer {
  /* position: absolute;
  bottom: 0;
  */
  width: 100%;

  background: #000000;

  padding: 15px 30px;

  display: flex;
  justify-content: space-between;
}

.Footer  a{ color: var(--main-color); }

.Footer .baseline{
  color: pink;
  text-transform: uppercase;
  font-family: 'Abel';
  /* font-weight: bold; */
  color: var(--main-color);
  font-size: 14pt;

  display: flex;
  gap: 10px;
  align-items: center;
}
/* .Footer .baseline a{ font-weight: bold; } */
.Footer .menu-bottom{
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 18pt;
}

.Footer .capgemini-logo{
  height: 35px;
  margin-top: 6px;
}