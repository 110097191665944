@font-face {
    font-family: 'Geo';
    src: url('Geo-Regular.woff2') format('woff2'),
        url('Geo-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Abel';
    src: url('AbelPro-Bold.woff2') format('woff2'),
        url('AbelPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Abel';
    src: url('Abel-Regular.woff2') format('woff2'),
        url('Abel-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
