.endgame-ctnr {
  height: 100%;
  background: url('../../images/Bg_fin.jpg');

  display: flex;
  justify-content: center;
  align-items: center;
}

.EndPopup {
  background: #000;
  border: none;
  /* border: 25px solid rgba(0, 128, 0, 0.35); */
  box-shadow: 0 0 0 25px rgba(0, 128, 0, 0.35);
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  height: 100%;
  /* width: 45%; */
  width: 840px;
  padding: 50px 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  font-size: 20pt;

  white-space: pre-wrap;


  position: static;
}
.EndPopup.lost {
  background: #1F0000;
  /* border-color: rgba(105, 10, 16, 0.35); */
  box-shadow: 0 0 0 25px rgba(227, 6, 21, 0.3);

}



.EndPopup .absolute-logos {
  position: absolute;

  right: 60px;
  bottom: 60px;


  width: 240px;

  display: flex;
  flex-direction: column;
  gap: 30px;
}



.EndPopup .EndPopup-logo {
  width: 60%;
  margin: 0 auto;
}

.EndPopup .Popup-title {
  text-transform: uppercase;
  color: #FFF;
  font-size: 50pt;
  font-family: "Geo";
  --gradient-color: rgba(133, 202, 80, 0.8);
  background: linear-gradient(to right, transparent, var(--gradient-color)  20%, var(--gradient-color) 80%, transparent);
}
.EndPopup.lost .Popup-title {
  --gradient-color: rgba(190, 22, 33, 0.47);
}

.EndPopup .EndPopup-text {
  font-size: 30pt;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  color: #fff;

  margin-bottom: 20px;

  min-height: 2px;
}

.EndPopup .EndPopup-game-datas {

  padding: 30px 0;
  padding-bottom: 0;
  margin-bottom: 30px;
  background:rgba(0, 128, 0, 0.2) ;
  font-family: "Geo";
  color: #FFF;
  position: relative;
}

.EndPopup.lost .EndPopup-game-datas { background: rgba(190, 22, 33, 0.16); }


.EndPopup .EndPopup-game-datas::before,
.EndPopup .EndPopup-game-datas::after {
  z-index: 0;
  content: "";
  height: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('../../images/bande.png');
}
.EndPopup.lost .EndPopup-game-datas::before,
.EndPopup.lost .EndPopup-game-datas::after { background: url('../../images/bande_blanche.png'); }

.EndPopup .EndPopup-game-datas::after {
  top: auto;
  bottom: 0;
}


.EndPopup .EndPopup-game-datas .game-data { margin-bottom: 20px; }

.EndPopup .EndPopup-game-datas .game-data-value {
  color: var(--main-color);
  font-size: 80px;
  line-height: 70px;
}
.EndPopup.lost .EndPopup-game-datas .game-data-value { color: #FFF;}

.EndPopup .EndPopup-game-datas button {
  position: relative;
  z-index: 1;
  transform:  translateY(50%);
}
.EndPopup .EndPopup-link {
  color: var(--main-color);
  text-transform: uppercase;
  font-family: "Geo";
}

.EndPopup.lost .EndPopup-link { color: #FFF;}


.EndPopup .EndPopup-advice {
  color: var(--main-color)
}
.EndPopup .EndPopup-advice .EndPopup-advice-title{
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  font-size: 30pt;
}
.EndPopup .EndPopup-advice .EndPopup-advice-text{
  max-width: 70%;
  margin: 5px auto;
  font-size: 25pt;

}


.EndPopup .EndPopup-game-datas.lost-button{
  padding-top: 0;
  margin-top: 40px;
  padding-bottom: 30px;
}
.EndPopup .EndPopup-game-datas.lost-button button {
  transform:  translateY(-50%);
}