.SettingsPopup .Popup-content {
  margin-top: 50px;
}
.SettingsPopup .SettingsPopup-option {
  display: flex;
  align-items: center;
  height: 45px;
  color: #FFF;

}

.Switch input{
  display: none;
}
.Switch span{
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
  border: 3px solid  var(--main-color);
  width: 180px;
  height: 100%;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px;
  line-height: 0;
  user-select: none;

  color: var(--text-color);

}


.Switch.checked span{
  background: #85ca5080;
}
.Switch:not(.checked) span::before,
.Switch.checked span::after{
  content: "";
  width: 35px;
  height: 35px;
  background: #FFF;
  border-radius: 50%;
}