.Header {
  background: var(--main-color);
  margin: 0;
  padding: 15px 10px;
  box-shadow: 0 4px 5px rgba(74, 114, 77, 0.2);
  display: flex;
  align-items: center;
}
.Header h1{
  margin: 0;
  margin-right: 20px;
}


.Header .spacer {
  flex-grow: 1;
}
.Header .language-selector {
  margin: 0 10px;
}