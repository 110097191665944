* {box-sizing: border-box;}

:root {
  --main-color: #84ca50;
  --text-color: #aea2c2;
}


html, body, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: sans-serif;
}

body {
  background: #000;
}
a {
  color: var(--main-color);
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  font-family: "Abel";
  font-size: 16pt;
  /* padding-bottom: 30px; */
  /* width: 1920px;
  height: 1080px; */
}

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 7pt;
  color: rgba(255, 255, 255, 0.5);
}
.debug {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  color: #FFF;
  background: red;

}


.screen {
  flex-grow: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}
.screen .screen-content {
  flex-grow: 1;
}


button {
  background: var(--main-color);
  font-size: 23pt;

  text-transform: uppercase;

  padding: 20px 40px;
  cursor: pointer;
  margin: 0 30px;
  position: relative;

  font-family: "Abel";
    font-weight: bold;

  border: none;

  filter: drop-shadow(0 0 10px rgba(133, 202, 80, 0.9));
}
button::after,
button::before {
  content: "";
  width: 23px;
  background: url('../../images/btn_side.png') no-repeat center right;
  background-size: 100% 100%;
  position: absolute;
  right: calc(100% - 1px);
  bottom: 0;
  height: 100%;

}
button::after {
  right: auto;
  left: calc(100% - 2px);
  transform: scaleX(-1);
}

button:hover {
  /* opacity: 0.8; */
  filter: brightness(1.1) drop-shadow(0 0 10px rgba(133, 202, 80, 0.9));

}
button:active {
  filter: brightness(1.2) drop-shadow(0 0 10px rgba(133, 202, 80, 0.9));
}

button:focus{
  outline: none;
  filter: brightness(1.2) drop-shadow(0 0 10px rgba(133, 202, 80, 0.9));
}



button.white { background: #FFF; filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.9)); }
button.white::after, button.white::before { background-image: url('../../images/btn_side_white.png'); }




button.link-btn {

  background: none;
  border: none;
  padding: 0;
  color: darkblue;
  cursor: pointer;
}
button.link-btn:hover {
  text-decoration: underline;
}

img {max-width: 100%;}

.error {
  color: darkred;
}

.App2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}