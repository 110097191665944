.PopupVideo {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PopupVideo video{
  width: 100%;
  height: 100%;
  pointer-events: none;

}

.PopupVideo button{
  position: absolute;
  bottom: 10px;
  right: 10px;

}