.Popup-overlay {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.Popup {
  border: 4px solid var(--main-color);

  background: #000;
  color: var(--text-color);

  max-width: 70%;
  border-radius: 20px;
  padding: 30px 80px;

  position: relative;
}
.Popup .Popup-close{
  position: absolute;
  top: 0;
  right: 0;

  width: 90px;
  height: 90px;
  border: 4px solid var(--main-color);
  border-radius: 50%;
  transform: translateX(50%)  translateY(-50%);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  font-size: 80px;

  cursor: pointer;
}

.Popup .Popup-close:hover{
  background: var(--main-color);
  color: #000;
}


.Popup .Popup-title{
  /* border: 1px solid  red; */

  color: var(--main-color);

  background: linear-gradient(to right, transparent, rgba(133, 202, 80, 0.4), transparent);
  text-align: center;
  font-family: "Geo";
  font-size: 40pt;
  margin: 10px 0;
  margin-bottom: 30px;
}

.Popup .Popup-content{
  font-size: 20pt;
  text-align: center;

  white-space: pre-wrap;
}